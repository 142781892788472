.wrapper {
    .toggle,
    .toggle[data-active="true"] {
        width: 70px;
        height: 30px;
        padding: 5px;
        background-color: #FFEB89;
        border-radius: 20px;
        position: relative;
        transition: all ease-in-out 500ms;
        cursor: pointer;
        
        
        .switch {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #4A26FF;
            position: absolute;
            right: 5px;
            transition: all ease-in-out 500ms;
        }
    }
    
    .toggle[data-active="false"] {
        background-color: #EFEEEE;
        justify-content: flex-start;
        
        .switch {
            background-color: #FF5552;
            right: 45px;
        }
    }
}