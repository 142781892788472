.nav{
    z-index: 2;
    padding-top: 0.25rem;
}
.nav[data-fix='true']{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    min-height: 6.5rem;
    margin-top: 0 !important;
}

.nav[data-fix]{
    margin-top: 0rem;
}

.search-area {
    background-color: #fff;
    height: calc(100vh - 6.5rem);
    width: 100%;
    overflow: auto;
}

.noScroll{
    overflow: hidden;
}

.bg-navbar{
    height: 6.5rem;
    border-radius: 1rem 1rem 0 0;
    background: var(--navbar-bg);
}
