.wrapper {
    background-position: center;
    background-size: cover;
    border-radius: 10pt;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    justify-items: flex-start;
    gap: 0.25rem;
    padding: 1rem 0.5rem;
    overflow: hidden;
    -webkit-user-drag: none;
    user-drag: none;
    user-select: none;
    * {
        -webkit-user-drag: none;
        user-drag: none;
        user-select: none;
    }

    transition: all 800ms ease-in-out;

    .wrapper-bg {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        transition: all ease-in-out 800ms;
        z-index: 1;
    }

    @media (min-width:500px) {
        .title {
            * {
                font-size: xx-large;
            }
        }
    }

    @media (min-width:768px) {
        .title {
            * {
                font-size: x-large;
            }
        }
    }

    @media (min-width:992px) {
        .title {
            * {
                font-size: small;
            }
        }
    }

    @media (min-width:1200px) {
        .title {
            * {
                font-size: medium;
            }
        }
    }

    @media (min-width:1400px) {
        .title {
            * {
                max-width: 140px;
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

}

@media (min-width:768px) {
    .wrapper {
        border-radius: 16pt;
        padding: 1rem;
    }
}



.wrapper[data-scale-on-hover='true']:hover {
    .wrapper-bg {
        transform: scale(1.1);
    }
}

.wrapper {
    .cta {
        width: fit-content;

        button {
            position: absolute;
            max-width: calc(100% - 2rem);
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.5s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            padding: 0.75rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.5rem;
            border: unset;
            min-width: 150px;
            z-index: 99;
        }
    }

    .cta[data-display-cta="false"] {
        button[data-btn-state="cta"] {
            display: none;
        }
    }

    .cta[data-display-cta="true"] {
        button[data-btn-state="cta"] {
            opacity: 1;
        }
    }


    .cta[data-display-cta-hover="true"] {
        button[data-btn-state="cta-hover"] {
            opacity: 0;
        }
    }
}

.wrapper:hover {
    .cta[data-display-cta="true"] {
        button[data-btn-state="cta"] {
            opacity: 0;
        }
    }


    .cta[data-display-cta-hover="true"] {
        button[data-btn-state="cta-hover"] {
            opacity: 1;
        }
    }
}

.text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    box-orient: vertical;
}