.search-container {
  position: relative;
}

.filter-input {
  background: #fff;
  border-radius: 20pt;
  color: var(--primary);
  max-width: 280px;
  max-height: 39px;
  border: 1px solid var(--primary);

  input {
    width: 100%;
    border-radius: unset;
    background: #fff;
    border: none;
    color: var(--primary);
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--primary);
      opacity: 1;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: var(--primary);
    }
  }
}

.filter-input[data-focus="true"] {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.00);
  outline: none;

  input {
    text-align: center;
  }
}

.search-area {
  background-color: #fff;
  min-height: 95dvh;
  width: 100%;
}

@media (min-width:992px) {
  .filter-input {
    input {
      display: block;
    }
  }

}

@media (min-width:992px) {
  .filter-input {
    width: 250px !important;
  }
}

@media (min-width:1400px) {
  .filter-input {
    width: 350px !important;
  }
}
