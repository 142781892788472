.link[data-status='true'] {
    white-space: nowrap;
    border-bottom-color: var(--primary);
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.loyalty-level {
    letter-spacing: 0.1rem;
    font-weight: normal;
    white-space: nowrap;
    line-height: 15px;
    padding-bottom: 2px;
}
