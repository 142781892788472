.wrapper {
    position: relative;
    .icon {
        position: absolute;
        top: 0.75rem;
        right: 1rem;
        transition: all 0.25s ease-in-out;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        justify-items: center;
        height: 23px;
        width: 23px;
    }

    .icon[data-state="expand"] {
        transform: rotate(180deg);
        transition: all 0.25s ease-in-out;
    }

    .content-box {
        overflow: hidden;
        transition: all 0.5s ease;
    }
}