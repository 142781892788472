.wrapper {
  background: var(--primary);
  height: var(--vh, 100dvh);
  border-radius: 0;
  flex-direction: column;
  max-height: var(--vh, 100dvh);
  overflow: auto;
  color: #fff;
}

.content-wrapper {
  height: var(--vh, 100dvh);
}

.section-wrapper {
  height: calc(var(--vh, 100dvh) - 3.75rem - 40px);
  overflow: auto;
  padding-bottom: 2rem;
  padding-right: 0.75rem;
}

.section-wrapper::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

.section-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}

.section-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
}
