.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--primary);
    border-radius: 0pt;
    z-index: 9999;
}

.wrapper[data-section="nav"] {
    border-radius: 12pt 12pt 0pt 0pt;
}


.nav[data-is-fixed="true"] {
    padding: 1rem;
    background: var(--primary);
}

.content{
    flex: 1;
    height: calc(var(--vh, 100dvh) - 67px - 1rem);
    overflow: auto;
    >div{
        min-height: calc(var(--vh, 100dvh) - 67px - 1rem);
    }
}

.filter-input {
    background: #fff;
    border-radius: 20pt;
    color: var(--primary);

    input {
        border-radius: unset;
        background: #fff;
        border: none;
        color: var(--primary);
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        padding: 0px;
        padding-bottom: 5px;
    }
}
.noScroll {
    overflow: hidden; 
}