.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--primary);
    border-radius: 0pt;
    z-index: 9999;
    max-height: 100dvh;
}

.wrapper[data-section="nav"],
.wrapper[data-section="menu"] {
    border-radius: 12pt 12pt 0pt 0pt;
}


.nav[data-is-fixed="true"] {
    background: var(--primary);
}

.content {
    flex: 1;
    height: auto;
    overflow: auto;
}

.filter-input {
    background: #fff;
    border-radius: 20pt;
    color: var(--primary);

    input {
        border-radius: unset;
        background: #fff;
        border: none;
        color: var(--primary);
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        padding: 0px;
        padding-bottom: 5px;
    }
}

.noScroll {
    overflow: hidden;
}
