.input-group-wrapper {
    width: 100%;
    height: fit-content;

    label {
        width: 100%;
        height: fit-content;
        margin-top: 20px;
        position: relative;
        display: inline-block;
    }

    .animated-label {
        padding: 0.15rem 0.5rem;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.75;
    }

    textarea {
        width: 100%;
        padding: 0.5rem;
        outline: unset;
        border: unset;
        background: var(--input-bg);
        border-bottom: solid 1px var(--input-border);
        border-radius: 6pt !important;
        color: var(--text-color);
        font-size: 16px;
    }

    textarea:read-only:focus {
        border-bottom: solid 1px var(--info) !important;
    }

    textarea:focus {
        box-shadow: unset;
        border-bottom: solid 1px var(--primary);
    }

    textarea:focus-within {
        box-shadow: unset;
        border-bottom: solid 1px var(--primary);
    }

    textarea::placeholder {
        color: transparent;
        opacity: 0;
    }

    textarea:-ms-input-placeholder {
        color: transparent;
    }

    textarea::-ms-input-placeholder {
        color: transparent;
    }

    textarea:focus+.animated-label,
    textarea:not(:placeholder-shown)+.animated-label {
        opacity: 1;
        transform: scale(1.25) translateY(-100%) translateX(5%);
        color: #d9dadb ;
    }

    /* For IE Browsers*/
    textarea:focus+.animated-label,
    textarea:not(:-ms-input-placeholder)+.animated-label {
        opacity: 1;
        transform: scale(1) translateY(-100%) translateX(-5%);
        color: #d9dadb !important;
    }

    .feedback {
        color: var(--primary);
    }

    .eye-icon {
        position: absolute;
        right: 5px;
        top: 50%; 
        transform: translateY(-40%);
        cursor: pointer;
    }
}


.input-group-wrapper[data-status='success'] {

    label,
    .animated-label,
    .feedback {
        color: var(--success);
    }

    textarea {
        border-bottom: solid 1px var(--success);
    }
}

.input-group-wrapper[data-status='error'] {

    label,
    .animated-label,
    .feedback {
        color: var(--error);
    }

    textarea {
        border-bottom: solid 1px var(--error);
    }
}

.input-group-wrapper[data-status='warning'] {

    label,
    .animated-label,
    .feedback {
        color: var(--warning);
    }

    textarea {
        border-bottom: solid 1px var(--warning);
    }
}

.input-group-wrapper[data-status='info'] {

    label,
    .animated-label,
    .feedback {
        color: var(--info);
    }

    textarea {
        border-bottom: solid 1px var(--info);
    }
}