.container{
    cursor: pointer;
    width: 25%;
    min-width: max-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}
.hamburger-icon {
    width: 20px;
    height: 12px;
    transition: all 1.5s ease-out;
    position: relative;
}

.hamburger-icon div {
    width: 20px;
    height: 2px;
    background: #fff;
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 0;
    margin: auto;
    
}

.hamburger-icon div:nth-child(2) {
    top: 10px;
}

.hamburger-icon[data-is-open="true"] div:first-child {
    transition: transform 0.5s ease-in-out 0.5s;  /* Add delay here */
    transform: rotate(45deg);
    top: 5px;
    left: 1px;
}

.hamburger-icon[data-is-open="true"] div:nth-child(2) {
    transition: transform 0.5s ease-in-out 0.5s;  /* Add delay here */
    transform: rotate(-45deg);
    top: 5px;
    left: 1px;

}
