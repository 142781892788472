.value-wrapper {
    position: absolute;
    height: calc(54 / 16 * 1rem);
    max-height: 54px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 0.3rem;

    img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 50%;
    }
}
.content{
    width: 100%;
        padding: 0.5rem;
        outline: unset;
        border: unset;
        background: var(--input-bg);
        border-bottom: solid 1px var(--input-border);
        border-radius: 6pt !important;
        color: #8C8C8C;
        font-size: 16px;
        :focus{
            border-bottom: solid 2px var(--secondary);
        }

}

.input{
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
}
.input:focus{
    border-bottom: solid 2px var(--secondary);
}
.input:focus{
    .content{
        border-bottom: solid 2px var(--secondary);
    }
}