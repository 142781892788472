.content{
  input {
    width: 100%;
    padding: 0.4rem;
    outline: unset;
    border: unset;
    background: var(--input-bg);
    border-bottom: solid 1px var(--input-border);
    border-radius: 6pt !important;
    color: #8C8C8C;
    font-size: 16px;
    text-align: center;
  }
}