.row {
    * {

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #000
    }

    p {
        -webkit-line-clamp: 1;
        line-clamp: 1;
    }
    section{
        max-height: 4rem;
    }
    background: #EFEEEE;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 6pt;
}

.row:nth-child(even) {
    background: #EFEEEE;
}

.row:hover {
    background: #EFEEEE;
    font-weight: 600;

}
