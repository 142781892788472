.wrapper {
    background-position: center;
    background-size: 100%;
    border-radius: 16pt;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    justify-items: flex-start;
    gap: 0.5rem;
    transition: all 800ms ease-in-out;
    overflow: hidden;
    -webkit-user-drag: none;
    user-drag: none;
    user-select: none;

    * {
        -webkit-user-drag: none;
        user-drag: none;
        user-select: none;
    }

    .title {
        * {
            font-size: xx-large;
        }
    }

    .graph {
        position: relative;

        .wrapper-thumb {
            width: 100%;
            height: 100%;
            content: '';
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            border-radius: 8pt;
            transform-origin: center center;
            transition: all ease-in-out 800ms;
        }

        .wrapper-thumb[ data-img-type="thumb-hover"] {
            width: 0%;
            opacity: 0;
        }
    }
}

.wrapper:hover {

    .graph[data-change-on-hover="true"] {
        .wrapper-thumb[data-img-type="thumb-hover"] {
            width: 100%;
            aspect-ratio: 1 / 1;
            border-radius: 8pt;
            transform-origin: center center;
            transition: all ease-in-out 800ms;
            opacity: 1;
        }

        .wrapper-thumb[data-img-type="thumb"] {
            width: 0%;
            opacity: 0;
        }
    }
}

.wrapper[data-scale-on-hover='true']:hover {
    .graph {
        .wrapper-thumb {
            transform: scale(1.04);
        }
    }
}

.wrapper {
    .cta {
        width: 0px;

        button {
            position: absolute;
            width: 100%;
            max-width: calc(100% - 2rem);
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.5s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            padding: 0.75rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.5rem;
            border: unset;
            min-width: 150px;
        }
    }

    .cta[data-display-cta="false"] {
        button[data-btn-state="cta"] {
            display: none;
        }
    }

    .cta[data-display-cta="true"] {
        button[data-btn-state="cta"] {
            opacity: 1;
        }
    }


    .cta[data-display-cta-hover="true"] {
        button[data-btn-state="cta-hover"] {
            opacity: 0;
        }
    }
}

.wrapper:hover {
    .cta[data-display-cta="true"] {
        button[data-btn-state="cta"] {
            opacity: 0;
        }
    }


    .cta[data-display-cta-hover="true"] {
        button[data-btn-state="cta-hover"] {
            opacity: 1;
        }
    }
}