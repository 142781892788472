.input-group-wrapper {
    width: 100%;
    height: fit-content;
 

    label {
        width: 100%;
        height: fit-content;
        margin-top: 20px;
        position: relative;
        display: inline-block;
        font-size: 16px;
    }

    .animated-label {
        padding: 0.15rem 0.5rem;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity: 0.75;
    }

    // .animated-label[data-ready-only="true"]{
        // *{
        //     color: #8C8C8C !important; 
        // }
    // }

    input {
        width: 100%;
        padding: 0.5rem;
        outline: unset;
        border: unset;
        background: #fff;
        border-radius: 6pt !important;
        color: #8C8C8C;
        font-size: 16px;
        border: solid 1px #999CA6;
        height: calc(54 / 16 * 1rem);
        max-height: 54px;
    }
    input:read-only{
        border: solid 1px var(--input-bg);
        background: var(--input-bg);
    }
    input:read-only:focus {
        border: solid 1px var(--input-bg) !important;
    }

    input:focus {
        box-shadow: unset;
        border-bottom: solid 1px var(--secondary);
    }

    input:focus-within {
        box-shadow: unset;
        border-bottom: solid 1px var(--secondary);
    }

    input::placeholder {
        color: #777;
        opacity: 0;
    }

    input:-ms-input-placeholder {
        color: #777;
    }

    input::-ms-input-placeholder {
        color: #777;
    }

    input:focus+.animated-label,
    input:not(:placeholder-shown)+.animated-label {
        opacity: 1;
        transform: scale(1.25) translateY(-100%) translateX(5%);
    }

    /* For IE Browsers*/
    input:focus+.animated-label,
    input:not(:-ms-input-placeholder)+.animated-label {
        opacity: 1;
        transform: scale(1) translateY(-100%) translateX(-5%);
    }

    .feedback {
        color: var(--primary);
    }

    .eye-icon {
        position: absolute;
        right: 5px;
        top: 50%; 
        transform: translateY(-40%);
        cursor: pointer;
    }
}


.input-group-wrapper[data-status='success'] {

    label,
    .animated-label,
    .feedback {
        color: var(--success);
    }

    input {
        border-bottom: solid 1px var(--success);
    }
}

.input-group-wrapper[data-status='error'] {

    label,
    .animated-label,
    .feedback {
        color: var(--error);
    }

    input {
        border-bottom: solid 1px var(--error);
    }
}

.input-group-wrapper[data-status='warning'] {

    label,
    .animated-label,
    .feedback {
        color: var(--warning);
    }

    input {
        border-bottom: solid 1px var(--warning);
    }
}

.input-group-wrapper[data-status='info'] {

    label,
    .animated-label,
    .feedback {
        color: var(--info);
    }

    input {
        border-bottom: solid 1px var(--info);
    }
}
.animated-label-value{
    color: var(--primary)
}