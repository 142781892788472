.playAnimation::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid #EED55A;
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  animation: scaling-border 0.3s ease-in-out 3;
  z-index: -1; /* Behind the container */
}

.progress-bar-container {
  background-color: var(--primary);
  border-radius: 4px;
  overflow: hidden;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  height: 0.625rem;

  &.growing {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(255, 235, 137, 0.5);
    z-index: 0;
  }
}

.progress-bar-container[data-border="primary"] {
  border-color: var(--primary);
}

.progress-bar-container[data-border="secondary"] {
  border-color: #ffeb89;
}

.progress {
  height: 100%;
  background-color: #ffeb89;
  transition: width 0.8s ease-in-out;
  border-radius: 0.5rem;
}

@keyframes scaling-border {
  0% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
