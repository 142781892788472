.input-group-wrapper {
    width: 100%;
    padding: 0.5rem;
    outline: unset;
    border: unset;
    background: #fff;
    border-radius: 6pt !important;
    color: #8C8C8C;
    font-size: 16px;
    border: solid 1px #999CA6;
    height: 3.375rem;
    max-height: 54px;

    input {
        border: none !important;
        color: #8C8C8C;
        background: transparent;
    }

    .prefix {
        border-right: solid 1px #8C8C8C !important;
        width: 40px;
    }

    .number {
        width: calc(100% - 40px - 6rem);
    }

}

.input-group-wrapper[data-read-only="true"] {
    background-color: var(--input-bg);
    pointer-events: none !important;
    user-select: none !important;
    
    * {
        pointer-events: none !important;
        user-select: none !important;
    }
}